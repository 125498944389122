const logoutLink = document.getElementById("logout");
const myProgressLink = document.getElementById("my-progress-link");

// common authentication logic
export function handleAuthStateChanged(user) {
  if (user) {
    //console.log('signed in as ' + user.email);

    logoutLink.style.display = "inline";
    myProgressLink.style.display = "inline";
  } else {
    //console.log('not signed in');

    logoutLink.style.display = "none";
    myProgressLink.style.display = "none";
  }
}
