/********************/
// change to false before committing changes
const EMULATE = false;
/********************/

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAKWLODtljKsAl0YVT29rY-0MwMyAG6tUE",
  authDomain: "comprendo-tv.firebaseapp.com",
  projectId: "comprendo-tv",
  storageBucket: "comprendo-tv.appspot.com",
  messagingSenderId: "209563753135",
  appId: "1:209563753135:web:1c5212d37ada8793b5525a",
  measurementId: "G-CKJGJ7S4J8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Set up analytics, auth and functions
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (EMULATE) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export {
  app,
  analytics,
  auth,
  functions,
  onAuthStateChanged,
  httpsCallable,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
};
