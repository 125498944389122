import { auth, onAuthStateChanged } from "./init-firebase";
import { handleAuthStateChanged } from "./auth";

const chromeCTAButton = document.getElementById("chrome-extension-cta");
chromeCTAButton.addEventListener("click", () => {
  window.location.href = "/coming-soon.html";
});

onAuthStateChanged(auth, (user) => {
  handleAuthStateChanged(user);
});
